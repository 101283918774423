import React from 'react';
import { Container } from '@mui/material';
import { useSearchDealsPublic } from '../../components/hooks/deals/use-deals';
import { useSnackbar } from '../../components/hooks/util/use-snackbar';
import ScrollToTop from '../../components/util/scroll-to-top';
import { TopNavPublic } from '../../components/layout/top-nav-public';
import { SearchContent } from '../../components/deals/search/search-content';

const SearchPublic = () => {
  const { render: renderSnackbar, setSnackbar } = useSnackbar();
  const { data: searchResults, isLoading: isLoadingSearch } =
    useSearchDealsPublic();

  return (
    <>
      <ScrollToTop />
      <TopNavPublic />
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        {renderSnackbar()}
        <SearchContent
          searchResults={searchResults}
          isLoadingSearch={isLoadingSearch}
          setSnackbar={setSnackbar}
          isPublic
        />
      </Container>
    </>
  );
};

export default SearchPublic;
