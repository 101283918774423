import './App.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { Login } from './pages/login';
import OrganizationPublic from './pages/public/organization-public';
import DealPublic from './pages/public/deal-public';
import SearchPublic from './pages/public/search-public';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/public/organizations/:linkId"
          element={<OrganizationPublic />}
        />
        <Route path="/public/search" element={<SearchPublic />} />
        <Route path="/public/deals/:externalId" element={<DealPublic />} />
        <Route path="*" element={<Login />} />
      </>,
    ),
  );

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: 'openid profile email offline_access',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <RouterProvider router={router} />
    </Auth0Provider>
  );
}

export default App;
