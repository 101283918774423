import React from 'react';
import { isBefore } from 'date-fns';
import { common } from '@mui/material/colors';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ContactButton } from './contact-button';
import { WatchlistButton } from './watchlist-button';
import { DealType } from '../enums/deal-type.enum';
import { localDate } from '../../helpers/dates';
import { DealTypeChip } from './chips/deal-type-chip';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { Role } from '../enums/role.enum';

export const DealBanner = ({ deal, isSearch, setSnackbar }) => {
  const { sessionUser } = useSessionUser();
  const isSale = deal?.type === DealType.Sale;
  const offerDate = deal?.offeredAt
    ? typeof deal?.offeredAt === 'string'
      ? localDate(deal.offeredAt)
      : deal.offeredAt
    : null;
  const isComingSoon = !offerDate || isBefore(new Date(), offerDate);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems={'center'}
      sx={{
        backgroundColor: common.black,
        px: 2,
        py: 1,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
      }}
    >
      <Typography align={'center'} color={'text.inverted'} variant={'caption'}>
        {isComingSoon
          ? 'COMING SOON'
          : isSale
            ? 'PROPERTY FOR SALE'
            : 'OPEN FOR INVESTMENT'}
      </Typography>
      <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
        <DealTypeChip type={deal?.type} sx={{ mr: 1 }} />
        {isSearch && sessionUser?.role === Role.Investor && (
          <>
            <ContactButton
              deal={deal}
              fullWidth
              setSnackbar={setSnackbar}
              iconOnly
            />
            <WatchlistButton
              fullWidth
              deal={deal}
              setSnackbar={setSnackbar}
              iconOnly
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
