import { useQuery } from 'react-query';
import { useApi } from '../api/use-api';
import { DealQueryKeys, enrichDeals } from './deals-hook-utils';
import { RetryConfig } from '../api/retry-config';

const useManageDeals = () => {
  const request = useApi();
  return useQuery(
    DealQueryKeys.manage(),
    async () => {
      const response = await request({ url: '/deals' });
      // console.log('response: ' + JSON.stringify(response, null, 2));
      return enrichDeals(response);
    },
    {
      ...RetryConfig(),
    },
  );
};

const useSearchDeals = () => {
  const request = useApi();
  return useQuery(
    DealQueryKeys.searches(),
    async () => {
      const response = await request({ url: `/deals/search` });
      // console.log('response: ' + JSON.stringify(response, null, 2));
      return enrichDeals(response);
    },
    {
      ...RetryConfig(),
    },
  );
};

const useWatchlistDeals = () => {
  const request = useApi();
  return useQuery(
    DealQueryKeys.watchlist(),
    async () => {
      const response = await request({ url: `/deals/watchlist` });
      // console.log('response: ' + JSON.stringify(response, null, 2));
      return enrichDeals(response);
    },
    {
      ...RetryConfig(),
    },
  );
};

const useApprovalDeals = () => {
  const request = useApi();
  return useQuery(
    DealQueryKeys.approvals(),
    async () => {
      const response = await request({ url: `/deals/approvals` });
      // console.log('response: ' + JSON.stringify(response, null, 2));
      return enrichDeals(response);
    },
    {
      ...RetryConfig(),
    },
  );
};

const useSearchDealsPublic = () => {
  const request = useApi(true);
  return useQuery(
    DealQueryKeys.searches(),
    async () => {
      const response = await request({ url: `/public/search` });
      return enrichDeals(response);
    },
    {
      ...RetryConfig(),
    },
  );
};

export {
  useManageDeals,
  useSearchDeals,
  useWatchlistDeals,
  useApprovalDeals,
  useSearchDealsPublic,
};
