import {
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { usePublicOrganization } from '../../components/hooks/organizations/use-organizations';
import { StorageBucket } from '../../helpers/supabase';
import { enrichDeals } from '../../components/hooks/deals/deals-hook-utils';
import { DealStatus } from '../../components/enums/deal-status.enum';
import { TopNavPublic } from '../../components/layout/top-nav-public';
import ScrollToTop from '../../components/util/scroll-to-top';
import React from 'react';
import { OrganizationDeals } from '../../components/organizations/organization-deals';

const IMG_WIDTH = 458;
const IMG_HEIGHT = 275;

export default function OrganizationPublic() {
  const { linkId } = useParams();

  const {
    data: organization,
    isLoading,
    error,
  } = usePublicOrganization({
    linkId,
  });

  const hasImg = organization?.logoImage;
  const logoImgSrc =
    hasImg &&
    `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.ORGANIZATION_IMAGES}/${organization.id}/${organization.logoImage}?w=${IMG_WIDTH}&h=${IMG_HEIGHT}&fit=max&auto=format`;

  const approvedDeals = organization?.deals
    ? enrichDeals(organization?.deals)
        .filter((deal) => deal.status === DealStatus.Approved)
        .map((deal) => {
          return {
            ...deal,
            organization: {
              id: organization.id,
              name: organization.name,
            },
          };
        })
    : [];

  return (
    <>
      <ScrollToTop />
      <TopNavPublic />
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        {isLoading || !organization ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack spacing={3}>
            <Stack
              sx={{
                pt: 2,
                px: 2,
                pb: 10,
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Stack direction={'row'} columnGap={5}>
                    {hasImg ? (
                      <img src={logoImgSrc} alt={organization.name} />
                    ) : (
                      <Typography variant={'h5'}>
                        {organization.name}
                      </Typography>
                    )}
                    <Stack>
                      <Typography variant="caption" color="text.secondary">
                        Company Bio
                      </Typography>
                      <Typography sx={{ minWidth: 350, maxWidth: 500 }}>
                        {organization.bio}
                      </Typography>
                    </Stack>
                    <Card sx={{ width: '200px' }} elevation={6}>
                      <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                          Active Deals
                        </Typography>
                        <Typography variant="h4">
                          {approvedDeals?.length || 0}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Stack>
                </Grid>
                <OrganizationDeals deals={approvedDeals} isPublic />
              </Grid>
            </Stack>
          </Stack>
        )}
      </Container>
    </>
  );
}
