import React from 'react';
import { Container } from '@mui/material';
import { useSearchDeals } from '../../components/hooks/deals/use-deals';
import { useSnackbar } from '../../components/hooks/util/use-snackbar';
import { SearchContent } from '../../components/deals/search/search-content';

const Search = () => {
  const { data: searchResults, isLoading: isLoadingSearch } = useSearchDeals();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();

  return (
    <Container maxWidth="xl" sx={{ pt: 2 }}>
      {renderSnackbar()}
      <SearchContent
        searchResults={searchResults}
        isLoadingSearch={isLoadingSearch}
        setSnackbar={setSnackbar}
      />
    </Container>
  );
};

export default Search;
