import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { OrganizationPopover } from './organization-popover';
import { usePopover } from '../hooks/util/use-popover';

export const OrganizationLink = ({ organization, isPublic }) => {
  const [openOrg, setOpenOrg] = useState(false);
  const orgPopover = usePopover();

  const openOrgPopup = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenOrg(true);
  };

  const closeOrgPopup = (e) => {
    e?.stopPropagation();
    setOpenOrg(false);
  };

  return (
    <>
      <Typography
        variant={'link'}
        sx={{ fontSize: 14 }}
        onClick={openOrgPopup}
        ref={orgPopover.anchorRef}
      >
        {organization?.name}
      </Typography>
      <OrganizationPopover
        organization={organization}
        anchorEl={orgPopover.anchorRef.current}
        onClose={closeOrgPopup}
        open={openOrg}
        isPublic={isPublic}
      />
    </>
  );
};
