import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const TopNavPublic = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSignupClick = () => {
    navigate(`/?am=signup`);
  };

  return (
    <AppBar
      position="static"
      style={{ marginTop: 0, backgroundColor: 'black' }}
    >
      <Toolbar disableGutters sx={{ px: 3, py: 1 }}>
        <Box
          sx={{
            display: isMobile ? 'none' : 'block',
          }}
        >
          <img
            alt="Opportunity Zone Deals Logo"
            src={'/images/logos/ozd_white_multi_color_inline.png'}
            style={{
              height: '50px',
            }}
          />
        </Box>
        <Box sx={{ flexGrow: 1, display: isMobile ? 'flex' : 'none' }}>
          <img
            alt="Opportunity Zone Deals Logo"
            src={'/images/logos/ozd_white_multi_color_inline.png'}
            style={{
              display: 'flex',
              height: '50px',
            }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: isMobile ? 'none' : 'flex',
            justifyContent: 'center',
          }}
        ></Box>

        <Stack direction="row" spacing={3}>
          <Button variant="contained" onClick={handleSignupClick}>
            Sign Up
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
